import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { DeleteIcon, UserIcon } from "../SVG";

export default function ListAdmin({ nameArea, nameLokasi }) {
  const [sugest, setSugest] = useState([]);
  const [dataAddNewAdmin, setDataAddNewAdmin] = useState({});
  const [listAdmin, setListAdmin] = useState([]);
  const handleOnSearch = (string, results) => {
    // console.log("ini handle sonSearch",string, results);
    if (string.length > 3) {
      // console.log(string);
      getSugesty(string);
      // console.log(sugest);
    }
  };

  const handleOnSelect = (item) => {
    // console.log(item);
    if (item.username !== undefined) {
      setDataAddNewAdmin(item);
      // console.log(item)
    }
  };

  const getSugesty = async (data) => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "auth/search",
        {
          cari: data,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setSugest(response.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleAddNewAdmin = async () => {
    if (
      dataAddNewAdmin.username !== null &&
      dataAddNewAdmin.username !== undefined
    ) {

      
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "admin/",
          {
            name: dataAddNewAdmin.name,
            nohp: dataAddNewAdmin.username,
            email: dataAddNewAdmin.email,
            area: dataAddNewAdmin.area,
            lokasi: nameLokasi,
          },
          {
            headers: {
              Authorization: process.env.REACT_APP_TOKEN,
            },
          }
        )
        .then((response) => {
          handleGetData();
          // console.log(response);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };
  const handleDeleteAdmin = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + `admin/` + id, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        handleGetData();
        // console.log(response.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleGetData = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "admin/", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        setListAdmin(response.data);
        // console.log(response.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="m-3">
      <div className="bg-white w-full rounded-lg dark:bg-gray-700">
        <ul
          className="h-48 py-2 max-h-60 overflow-y-auto text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownUsersButton"
        >
          {listAdmin.map((data) => (
            <li>
              <div className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white justify-between">
                <div className="cursor-pointer flex gap-3">
                  <UserIcon />
                  <div>{data.name}</div>
                  <div className="text-gray-500">{data.area}</div>
                </div>
                <div
                  onClick={() => handleDeleteAdmin(data._id)}
                  className="cursor-pointer opacity-25 hover:opacity-100"
                >
                  <DeleteIcon />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex items-center p-3 text-sm font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-blue-500 cursor-pointer gap-2">
          <div style={{ width: 400 }}>
            <ReactSearchAutocomplete
              items={sugest}
              onSearch={handleOnSearch}
              onSelect={handleOnSelect}
              autoFocus
            />
          </div>
          <div
            className="flex gap-1 bg-gray-100 p-2 rounded-lg hover:bg-gray-200"
            onClick={handleAddNewAdmin}
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z" />
            </svg>
            <div>Add</div>
          </div>
        </div>
      </div>
    </div>
  );
}
