import React, { useState } from "react";

export default function BroadcastTabs({sendMessage}) {
  const [message, setMessage] = useState("")
  const handleBroadcastMessage = () => {
    sendMessage(message)
    setMessage("");
  }
  return (
    <div>
      <div className="p-3">
        <textarea
        onChange={(e)=> setMessage(e.target.value)}
        value={message}
          type="text"
          className="w-full px-3 h-24 rounded-lg border-2 border-gray-600 bg-gray-200 bg-opacity-80 pb-10"
        />
        <div className="flex w-full mt-2 justify-end">
          <div onClick={handleBroadcastMessage} className="bg-gray-300 p-3  rounded-lg shadow-lg text-gray-900 font-bold cursor-pointer hover:bg-sky-900 hover:text-gray-50">
            Broadcast Now
          </div>
        </div>
      </div>
    </div>
  );
}
