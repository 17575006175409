import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

const ExportTrack = (props) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const room = props.room;
    const name = props.name;
    const startDate = new Date(props.startDate);
    const endDate = new Date(props.endDate);
    const [centerMap, setCenterMap] = useState([-122.486052, 37.830348]);
    const [dataUserGeoJSON, setDataUserGeoJSON] = useState(null);
    const [showLine, setShowLine] = useState(true);
    const [nameLocation, setNameLocation] = useState("");

    const handleExportTrackUser = (nameUserTrack, startDate, endDate, roomUserTrack) => {
        axios
            .get(
                process.env.REACT_APP_BASE_URL + `geolog/track?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&room=${roomUserTrack}&name=${nameUserTrack}`,
                {
                    headers: {
                        Authorization: process.env.REACT_APP_TOKEN,
                    },
                }
            )
            .then(function (response) {
                console.log("data", response)
                setDataUserGeoJSON(response.data.data);
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    const handleGetDataLocation = (room) => {
        axios
            .get(
                process.env.REACT_APP_BASE_URL + `area/locations/` + room,
                {
                    headers: {
                        Authorization: process.env.REACT_APP_TOKEN,
                    },
                }
            )
            .then(function (response) {
                setNameLocation(response.data.locations[0].name)
                setCenterMap(response.data.locations[0].coordinates);
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    useEffect(() => {
        handleExportTrackUser(name, startDate, endDate, room);
        handleGetDataLocation(room);
    }, []);

    useEffect(() => {
        // Set your Mapbox access token
        if (centerMap && dataUserGeoJSON) {
            mapboxgl.accessToken = 'pk.eyJ1IjoiZmFuZGlsbGFkcCIsImEiOiJja2t2bGhtdW8xNWE1MnBsbXR5bTFyNm94In0.Cw8RqeLPToDY7XpQuI4cjw';

            // Create a new Map instance
            const map = new mapboxgl.Map({
                container: 'map1', // Change 'map' to 'map1' to match your HTML structure
                style: 'mapbox://styles/mapbox/streets-v12',
                center: centerMap,
                zoom: 18
            });

            map.on('load', () => {
                map.addSource('route', {
                    type: 'geojson',
                    data: dataUserGeoJSON
                });

                if (showLine) {
                    map.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': 'blue',
                            'line-width': 5
                        }
                    });
                } else {
                    map.addLayer({
                        id: 'route',
                        type: 'circle',
                        source: 'route',
                        paint: {
                            'circle-radius': 8,
                            'circle-color': 'red'
                        }
                    });
                }
            });

            // Clean up the map instance on component unmount
            return () => map.remove();
        }
    }, [centerMap, dataUserGeoJSON, showLine]);

    const toggleLayer = () => {
        setShowLine(!showLine);
    };
    return (
        <div className='relative w-80 h-96 bg-blue-500'>
            <div id="map1" style={{ width: '100%', height: '100%' }} />
            <button onClick={toggleLayer} className='absolute top-2 right-2 bg-white text-red-500 p-2 rounded'>
                {showLine ? 'Switch to Circle' : 'Switch to Line'}
            </button>
            {/* <div className='absolute top-2 left-2 bg-gray-300 text-gray-900 p-2 rounded'>
                <div className="text-lg font-bold mb-2">
                    Informasi Data User Track
                </div>
                <div>
                    <p>Nama Pengguna: {name}</p>
                    <p>Lokasi: {nameLocation}</p>
                    <p>Mulai Tanggal: {startDate.toLocaleDateString()}</p>
                    <p>Selesai Tanggal: {endDate.toLocaleDateString()}</p>
                </div>
            </div>  */}
        </div>
    );
};

export default ExportTrack;
