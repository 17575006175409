import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { destroyCookie, parseCookies } from "nookies";
import Map from "./Map";
import Signin from "./auth/Signin";
import ExportTrack from "./components/ExportTrack/ExportTrack";
import NotFound from "./NotFound";

export default function App() {
  const [auth, setAuth] = useState(false);
  const [splashScreen, setSplashScreen] = useState(true);
  const cookies = parseCookies();
  const handleAuth = () => {
    setAuth(true);
  };

  const handleLogOut = () => {
    destroyCookie(null, "userId");
    destroyCookie(null, "username");
    destroyCookie(null, "nohp");
    destroyCookie(null, "area");
    destroyCookie(null, "role");

    setAuth(false)
  };

  useEffect(() => {
    if (cookies.userId) {
      // console.log(cookies.userId);
      axios
        .get(process.env.REACT_APP_BASE_URL + `admin/` + cookies.userId, {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        })
        .then(function (result) {
          // console.log("hasil",result);
          if (result.data._id === cookies.userId) {
            setAuth(true);
            setSplashScreen(false);
          } else {
            setSplashScreen(false);
            destroyCookie(null, "userId");
            destroyCookie(null, "name");
          }
        })
        .catch(function (error) {
          // console.log(error);
          setSplashScreen(false);
        });
    } else {
      setSplashScreen(false);
    }
  }, [cookies.userId]);

  return (
    <Router>
      <div>
        {auth === false && splashScreen === false && (
          <Routes>
            <Route path="/" element={<Signin handleAuth={handleAuth} />} />
            <Route path="*" element={<NotFound />} />

          </Routes>
        )}
        {auth === true && splashScreen === false && (
          <Routes>
            {/* <Route path="/persontrack" element={<ExportTrack />} /> */}
            <Route path="/" element={<Map handleLogOut={handleLogOut} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}